<template>
  <div class="download" :style="{backgroundImage: 'url(' + download_url + ')'}">
    <!-- <div class="title" style="float: left; margin-left: 0.2rem">
      <span>
        <van-icon
          @click="backToIndex"
          color="#D9D8E7"
          class="titlePStyle"
          name="arrow"
        />
      </span>
    </div> -->
	<div class="left" @click="onClickLeft">
		<div class="icon"><i class="icon_arrL"></i></div>
	</div>
	<!-- <div class="headIcon"><img src="@/assets/imgs/new_color/download_logo.png" alt="" /></div>
	<div class="headtxt"><img src="@/assets/imgs/new_color/download_txt.png" alt="" /></div>
	<div class="headtxt1"><img src="@/assets/imgs/new_color/download_txt1.png" alt="" /></div> -->
    <div class="shareFooter">
      <div>
        <span class="iosButton" @click="downLoad('ios')">
          <img src="../../assets/imgs/new_color/ios.png" />
          {{$t('system.IOSdown')}}
        </span>
      </div>
      <div>
        <a class="iosButton" :href="url">
          <img src="../../assets/imgs/new_color/android.png" />
          {{$t('system.androidDown')}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      refcode: this.$route.query.refcode,
      url: "",
      iosurl: "https://testflight.apple.com/join/C4lzH8eU",
	  download_url: ''
    };
  },
  created() {
    this.getConfig();
	this.getPictureList();
  },
  methods: {
    downLoad(type){
      if(type=='ios'){
        // iosurl
        this.$toast(this.$t('system.iosDownErr'));
      }
    },
    backToIndex() {
      this.$router.push("/");
    },
    getConfig() {
      this.$post2("/wallet/api/index/version")
        .then((res) => {
          this.url = res.apk.url;
          console.log(this.url);
        })
        .catch((e) => {});
    },
	//获取背景图
	getPictureList() {
		this.$post2('User/Api/Passport/getPictureList', {})
			.then(res => {
				this.download_url = res.download_url;
			})
			.catch(e => {
				// this.$toast.fail(e);
			});
	},
	onClickLeft() {
		this.$router.back(-1);
	}
  },
};
</script>

<style lang="less" scoped>
.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.headIcon {
	width: 1.5rem;
	margin: 0 auto;
	padding-top: 1.28rem;
	img {
		width: 100%;
		height: 100%;
	}
}
.headtxt {
	width: 4.95rem;
	margin: 0 auto;
	margin-top: 0.69rem;
	img {
		width: 100%;
		height: 100%;
	}
}
.headtxt1 {
	width: 4.06rem;
	margin: 0 auto;
	margin-top: 0.44rem;
	img {
		width: 100%;
		height: 100%;
	}
}
.download {
  // background-image: url("../../assets/imgs/share/backGroundImg2.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;

  .shareFooter {
	  // margin-top: 302px;
    
    position: absolute;
    bottom: 1.45rem;
    left: 0;
    width: 100%;

    div {
      width: 50%;
      float: left;

      a,span {
        display: block;
        margin: auto;
        width: 2.54rem;
        border-radius: 8px;
        height: 0.9rem;
        line-height: 0.9rem;
        font-size: 0.3rem;
        cursor: pointer;
      }

      .iosButton {
        color: #000;
		    font-weight: bold;
        background: #fff;
		    font-size: 0.32rem;
      }

      .androidButton {
        color: #fff;
        background: #5a54ea;
      }

      img {
        width: 0.45rem;
        vertical-align: -0.1rem;
      }
    }
  }
}
.left {
	position: absolute;
	left: .3rem;
	top: 0;
	.icon {
		width: .6rem;
		height: .88rem;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: .4rem;
			height: .4rem;	
		}
	}
}
.icon_arrL {
    border-top: .04rem solid #fff;
    border-left: .04rem solid #fff;
}
</style>
